import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessengerApiService {

  baseUrl = environment.messengerBaseUrl + '/api/';

  constructor(
    private _http: HttpClient
  ) { }

  public getCoversations(filter): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/list', filter);
  }

  public createCoversations(conv): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/create', conv);
  }

  public getMessages(filterObj): Observable<object> {
    return this._http.post(this.baseUrl + 'message/list', filterObj);
  }

  public sendMessage(message): Observable<object> {
    return this._http.post(this.baseUrl + 'message/send_message', message);
  }

  public deleteMessage(message): Observable<object> {
    return this._http.post(this.baseUrl + 'message/delete', message);
  }

  public editMessage(message): Observable<object> {
    return this._http.post(this.baseUrl + 'message/edit', message);
  }

  public markConversationAsRead(conversation): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/markread', conversation);
  }

  public tabUnreadCounts(): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/unreadcount', {});
  }

  public getConversationMembers(conversation): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/members', conversation);
  }

  public addConversationMembers(conversation): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/addmembers', conversation);
  }

  public editConversation(conversation): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/edit', conversation);
  }

  public leaveConversation(conversation): Observable<object> {
    return this._http.post(this.baseUrl + 'conversation/leave', conversation);
  }

  public getAnalyticsData(filter): Observable<object> {
    return this._http.post(this.baseUrl + 'analytics/getdata', filter);
  }

}
